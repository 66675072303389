<template>
  <div class="login-page">
    <div class="left-wrap">
<!--      <img src="../assets/img/logo_icon.png">-->
    </div>
    <div class="login-wrap">
      <div v-if="loginWay === 0 || loginWay === 1 || loginWay === 4" class="common-wrap">
        <div v-if="loginWay === 0 || loginWay === 1" class="title">
          <span :class="{'title-selected' : loginWay === 0 }" @click="checkWay(0)">手机号码登录</span>
          <span :class="{'title-selected' : loginWay === 1 }" @click="checkWay(1)">扫码快捷登录</span>
        </div>
        <div v-if="loginWay === 4" class="title password-title">
          <span class="title-selected" @click="checkWay(0)">手机号密码登录</span>
        </div>
        <div class="content">
          <!-- 验证码登录 -->
          <div v-if="loginWay === 0" class="phone-wrap">
            <div class="input-wrap">
              <img src="../assets/img/phone_icon.png" />
              <input v-model="dataForm.mobile" placeholder="请输入手机号码" />
            </div>
            <div class="code-wrap">
              <div class="input-wrap code-input">
                <img src="../assets/img/code_icon.png" />
                <input v-model="dataForm.captcha" placeholder="请输入验证码" @keyup.enter.prevent="submitLogin()" />
              </div>
              <div v-if="!isGetCode" class="code-btn" @click="getCode()">获取验证码</div>
              <div v-if="isGetCode" class="code-btn code-btn-disabled">{{ codeCount }}s</div>
            </div>
            <div class="login-btn" @click.prevent="submitLogin()">立即登录</div>
          </div>
          <!--快捷扫码登录-->
          <div v-if="loginWay === 1" class="code-blank">
            <template v-if="codeStatus === 0">
              <div class="code-info" ref="qrCodeUrl"></div>
              <span>打开泰祺考研APP扫码登录</span>
            </template>
            <template v-if="codeStatus === 1">
              <div class="code-satus-wrap">
                <img src="../assets/img/code_end_icon.png" />
                <span>扫描完成!</span>
                <p>请在手机上根据提示确认登录</p>
              </div>
            </template>
          </div>
          <!-- 手机号密码登录 -->
          <div v-if="loginWay === 4" class="phone-wrap">
            <div class="input-wrap">
              <img src="../assets/img/phone_icon.png" />
              <input v-model="passwordForm.account" placeholder="请输入手机号码" />
            </div>
            <div class="code-wrap">
              <div class="input-wrap">
                <img class="password-img" src="../assets/img/code_icon.png" />
                <input v-model="passwordForm.password" type="password" placeholder="请输入密码" @keyup.enter.prevent="submitPasswordLogin()" />
              </div>
            </div>
            <div class="login-btn" @click.prevent="submitPasswordLogin()">立即登录</div>
          </div>
        </div>
        <div v-if="loginWay === 4" class="title password-login-way">
          <span :class="{'title-selected' : loginWay === 0 }" @click="checkWay(0)">手机号码登录</span>
          <span :class="{'title-selected' : loginWay === 1 }" @click="checkWay(1)">扫码快捷登录</span>
        </div>
        <div class="other-wrap" :class="{'password-other-wrap' : loginWay !== 4}">
          <span v-if="loginWay !== 4" class="password-login" @click="checkWay(4)">密码登录</span>
          <span>第三方登录</span>
          <div>
            <img src="../assets/img/wx_icon.png" @click="checkWay(2)" />
            <img src="../assets/img/qq_icon.png" id="qqLoginBtn" @click="toQQLogin" />
          </div>
        </div>
      </div>
      <div v-if="loginWay === 2" class="wx-wrap">
        <div class="wx-top">
          <img src="../assets/img/wx_logo.png" />
          <span>微信扫码登录</span>
        </div>
        <div class="wx-code">
          <wx-login
            appid="wxded6cb25a18e31b3"
            scope="snsapi_login"
            response_type='code'
            :redirect_uri="encodeURIComponent(redirect_uri)"
            href="data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKCiBkaXNwbGF5OiBub25lOwoKfQoKLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CgogZGlzcGxheTogbm9uZTsKCn0KCi5pbXBvd2VyQm94IC5xcmNvZGUgewoKIGJvcmRlcjogbm9uZTsKCiB3aWR0aDogMjIwcHg7CgogaGVpZ2h0OiAyMjBweDsKbWFyZ2luLXRvcDogMDsKCn0K"
          />
        </div>
        <div class="way-wrap">
          <span @click="checkWay(0)">手机号码登录</span>
          <span @click="checkWay(1)">扫码快捷登录</span>
        </div>
        <div class="login-notice">登录即代表同意并阅读《用户协议》和《隐私政策 》</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Utils from '@/utils/index';
import { Message } from 'element-ui';
import * as loginService from '@/service/login-service';
import QRCode from 'qrcodejs2';
import wxLogin from 'vue-wxlogin';
export default {
  name: 'login',
  components: {
    wxLogin
  },
  data() {
    return {
      loginWay: 0, // 0手机号登录 1快捷登录 2微信登录 3qq登录 4密码登录
      dataForm: {
        mobile: '', // 手机号
        captcha: '' // 验证码
      },
      passwordForm: {
        account: '', // 手机号
        password: '' // 密码
      },
      redirect_uri: 'https://kaoyan.taiqischool.com/index.html#/callback/wechat',
      isGetCode: false,
      codeCount: 60,
      loginCode: '', // 快捷扫码登录code
      loadingService: '',
      codeInterval: null, // 登录code校验计时器
      codeStatus: 0 // 登录code是否扫码
    };
  },
  created() {
    const loginState = Utils.getLoginCondition();
    if (loginState) {
      this.$router.replace('/');
    }
  },
  methods: {
    /**
     * qq登录
     * */
    toQQLogin() {
      const callbackUrl = 'https://kaoyan.taiqischool.com/index.html#/callback/qq';
      const url = `https://graph.qq.com/oauth2.0/authorize?client_id=102055024&response_type=code&scope=all&redirect_uri=${encodeURIComponent(callbackUrl)}`;
      window.open(url);
    },
    checkWay(val) {
      this.loginWay = val;
      if (this.$refs.qrCodeUrl) {
        this.$refs.qrCodeUrl.innerHTML = '';
      }
      clearInterval(this.codeInterval);
      this.codeInterval = null;
      if (this.loginWay === 1) {
        this.getLoginCode();
      }
    },
    /**
     * 获取验证码
     * */
    getCode() {
      if (Utils.isVariableEmpty(this.dataForm.mobile)) {
        Message({
          message: '请输入手机号',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      if (!Utils.isMobile(this.dataForm.mobile)) {
        Message({
          message: '请输入正确的手机号',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      const data = {
        'mobile': this.dataForm.mobile,
        'event': 'mobilelogin'
      };
      loginService.getSmsSendCode(data).then((res) => {
        if (res.code === 1) {
          if (!this.isGetCode) {
            this.codeCount = 60;
            this.isGetCode = true;
            const codeVal = setInterval(() => {
              if (this.codeCount === 0) {
                this.isGetCode = false;
                this.codeCount = 60;
                clearInterval(codeVal);
              }
              else {
                --this.codeCount;
              }
            }, 1000);
          }
        }
        else {
          Message({
            message: res.msg,
            type: 'error',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
        }
      }).catch((error) => {
        Message({
          message: error.msg,
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
      });
    },
    /**
    * 手机号验证码登录
    * */
    submitLogin() {
      if (Utils.isVariableEmpty(this.dataForm.mobile)) {
        Message({
          message: '请输入手机号',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      if (!Utils.isMobile(this.dataForm.mobile)) {
        Message({
          message: '请输入正确的手机号',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      if (Utils.isVariableEmpty(this.dataForm.captcha)) {
        Message({
          message: '请输入验证码',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      const data = {
        ...this.dataForm
      };
      loginService.submitMobileLogin(data).then(res => {
        if (res.code === 1 && res.data.userinfo) {
          Utils.setLocalStorage('LoginState', true);
          Utils.setLocalStorage('LoginUser', res.data.userinfo);
          const path = this.$route.query.redirect ? this.$route.query.redirect : '/';
          this.$router.replace(path);
          Message({
            message: '登录成功',
            type: 'success',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
        }
        else {
          Message({
            message: res.msg,
            type: 'error',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
        }
      }).catch((error) => {
        Message({
          message: error.msg,
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
      });
    },
    /**
     * 手机号密码登录
     * */
    submitPasswordLogin() {
      if (Utils.isVariableEmpty(this.passwordForm.account)) {
        Message({
          message: '请输入手机号',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      if (!Utils.isMobile(this.passwordForm.account)) {
        Message({
          message: '请输入正确的手机号',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      if (Utils.isVariableEmpty(this.passwordForm.password)) {
        Message({
          message: '请输入密码',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      const data = {
        ...this.passwordForm
      };
      loginService.submitPassordLogin(data).then(res => {
        if (res.code === 1 && res.data.userinfo) {
          Utils.setLocalStorage('LoginState', true);
          Utils.setLocalStorage('LoginUser', res.data.userinfo);
          const path = this.$route.query.redirect ? this.$route.query.redirect : '/';
          this.$router.replace(path);
          Message({
            message: '登录成功',
            type: 'success',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
        }
        else {
          Message({
            message: res.msg,
            type: 'error',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
        }
      }).catch((error) => {
        Message({
          message: error.msg,
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
      });
    },
    /**
     * 获取扫码快捷登录code
     * */
    getLoginCode() {
      loginService.getLoginCode().then(res => {
        if (res.code === 1) {
          this.loginCode = res.data.code;
          this.createQRCode();
        }
      });
    },
    createQRCode() {
      this.$refs.qrCodeUrl.innerHTML = '';
      const code = new QRCode(this.$refs.qrCodeUrl, {
        text: `codelogin|${this.loginCode}`,
        width: 200,
        height: 200,
        colorDark: 'black',
        colorRight: 'white'
      });
      const _this = this;
      clearInterval(_this.codeInterval);
      _this.codeInterval = null;
      this.codeInterval = setInterval(() => {
        _this.checkLoginCode();
      }, 1000);
    },
    /**
     * 校验扫码登录状态
     * */
    checkLoginCode() {
      loginService.checkLoginCode(this.loginCode).then(res => {
        if (res.code === 1) {
          if (res.data.userinfo) {
            clearInterval(this.codeInterval);
            this.codeInterval = null;
            Utils.setLocalStorage('LoginState', true);
            Utils.setLocalStorage('LoginUser', res.data.userinfo);
            const path = this.$route.query.redirect ? this.$route.query.redirect : '/';
            this.$router.replace(path);
            Message({
              message: '登录成功',
              type: 'success',
              duration: 1500,
              customClass: 'element-error-message-zindex'
            });
            return;
          }
          if (res.data.code) {
            this.codeStatus = res.data.code.status;
            const currentTime = new Date().getTime();
            if (res.data.code.expire_time * 1000 - currentTime < 1) {
              clearInterval(this.codeInterval);
              this.codeInterval = null;
              this.codeStatus = 0;
              this.getLoginCode();
            }
          }
        }
        else {
          clearInterval(this.codeInterval);
          this.codeInterval = null;
          Message({
            message: res.msg,
            type: 'error',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
        }
      }).catch(() => {
        clearInterval(this.codeInterval);
        this.codeInterval = null;
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.codeInterval);
    this.codeInterval = null;
  }
};
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 315px);
  background: url("../assets/img/login_bg_img.png") no-repeat center top;
  background-size: cover;
  .left-wrap {
    margin-right: 120px;
    width: 630px;
    img {
      width: 630px;
      height: 391px;
    }
  }
  .login-wrap {
    width: 420px;
    height: 460px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 72px 0 90px;
    .common-wrap {
      display: flex;
      flex-direction: column;
      padding: 45px 40px 30px 40px;
      height: 100%;
      .title {
        display: flex;
        justify-content: space-between;
        margin-left: 2px;
        span {
          display: inline-block;
          height: 30px;
          font-size: 22px;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
          line-height: 30px;
        }
        .title-selected {
          color: #222222;
        }
      }
      .password-title {
        justify-content: center;
      }
      .password-login-way {
        margin-bottom: 20px;
        span {
          font-size: 16px;
          color: #2869F5;
        }
      }
      .content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .input-wrap {
          display: flex;
          align-items: center;
          padding: 0 14px;
          width: 340px;
          height: 50px;
          background: #FFFFFF;
          border: 1px solid #DDDDDD;
          margin-bottom: 30px;
          img {
            width: 19px;
            height: 26px;
            margin-right: 14px;
          }
          input {
            flex: 1;
            font-size: 16px;
            font-weight: 400;
            color: #222222;
          }
          input::placeholder {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
          }
          .password-img {
            width: 21px;
            height: 24px;
          }
        }
        .code-wrap {
          display: flex;
          justify-content: space-between;
          .code-input {
            width: 200px;
            img {
              width: 21px;
              height: 24px;
            }
            input {
              width: 139px;
            }
          }
          .code-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 50px;
            background: #2F6DF6;
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;
          }
          .code-btn-disabled {
            background: #77777D;
            cursor: default;
          }
        }
        .login-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          width: 340px;
          height: 50px;
          background: #2F6DF6;
          border-radius: 4px;
          font-size: 18px;
          color: #FFFFFF;
          cursor: pointer;
        }
        .code-blank {
          display: flex;
          flex-direction: column;
          .code-info {
            width: 200px;
            height: 200px;
            background: #FFFFFF;
            border: 1px solid #F8F8F8;
            margin-bottom: 29px;
          }
          span {
            font-size: 16px;
            font-weight: 400;
            color: #222222;
          }
          .code-satus-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 46px;
              height: 46px;
              margin-bottom: 40px;
            }
            span {
              font-size: 12px;
              color: #77777D;
              margin-bottom: 35px;
            }
            p {
              font-size: 16px;
              color: #282828;
            }
          }
        }
      }
      .other-wrap {
        margin: 0 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
        }
        .password-login {
          cursor: pointer;
          color: #2869F5;
        }
        img {
          width: 40px;
          height: 40px;
          margin-left: 35px;
          cursor: pointer;
        }
      }
      .password-other-wrap {
        margin: 0 8px;
      }
    }
    .wx-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      .wx-top {
        display: flex;
        align-items: center;
        margin-bottom: 23px;
        margin-top: 41px;
        img {
          width: 35px;
          height: 29px;
          margin-right: 14px;
        }
        span {
          font-size: 20px;
          font-weight: 400;
          color: #222222;
        }
      }
      .wx-code {
        width: 220px;
        height: 220px;
        background: #FFFFFF;
        border: 1px solid #FAFAFA;
        margin-bottom: 29px;
        overflow: hidden;
        ::v-deep iframe {
          width: 220px;
          height: 220px;
        }
      }
      .way-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 52px;
        width: 100%;
        padding: 0 51px;
        span {
          display: inline-block;
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
          color: #2869F5;
        }
      }
      .login-notice {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .first-wrap {
    padding: 45px 35px 43px !important;
    .title {
      margin-left: 7px;
      font-size: 22px;
      color: #222222;
    }
    .first-notice {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      text-align: center;
    }
  }
}
</style>
